import axios from "/@/utils/request";

const api = {
  getComboOrderList: '/base/comm/getComboOrderList',
};

export const getComboOrderList = (params: any) => {
  return axios({
    url: api.getComboOrderList,
    method: 'get',
    params,
  });
};