export default {
    login1:"前往官网",
    login2:"请输入电子邮箱账号",
    login3:"请输入密码",
    login4:"用户协议",
    login5:"隐私协议",
    login6:"联系客服",
    login7:"忘记密码",
    login8:"注册账号",
    login9:"点击联系客服",
    login10:"请输入邮箱",
    login11:"请输入正确邮箱格式",
    login12:"登录",
    login13:"请阅读并同意用户协议和隐私政策",
    login14:"欢迎",
    login15:"欢迎回来",
    login16:"我已阅读并同意",
    login17:"%channelName%翻译器/计数器管理后台",
    login18:"其他登录方式",
    login19:"使用 Google 账号登陆",
    login20:"使用 Telegram 账号登陆",
    otherLoginErr: "登陆失败请稍后再试, 或尝试其他方式登陆",
}
