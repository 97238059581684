export default {
    fansLinkComponents1:"Active Link Name:",
    fansLinkComponents2:"For example: XXX promotion (not filled in, default is date and time)",
    fansLinkComponents3:"Associated Platform：",
    fansLinkComponents4:"Please choose",
    fansLinkComponents5:"User binding：",
    fansLinkComponents6:"Turn on",
    fansLinkComponents7:"Close",
    fansLinkComponents8:"After closing, the same device will be reassigned a new number every time it is accessed, and it is possible for one user to add multiple numbers.",
    fansLinkComponents9:"After it is turned on, the numbers that meet the conditions are switched in a sequential cycle. The same device connects to the same number every time to prevent the same customer from adding multiple numbers.",
    fansLinkComponents10:"Binding method：",
    fansLinkComponents11:"IP binding",
    fansLinkComponents12:"Upload files：",
    fansLinkComponents13:"Click to upload",
    fansLinkComponents14:"Each account on a separate line, press Enter to separate multiple accounts",
    fansLinkComponents15:"The most you can lose",
    fansLinkComponents16:"Accounts",
    fansLinkComponents17:"Account number (duplicate removed)",
    fansLinkComponents18:"Please select a platform",
    fansLinkComponents19:"Please click to upload the file or enter the account number",
    fansLinkComponents20:"Created successfully",
    fansLinkComponents21:"Edited successfully",
    fansLinkComponents22:"Only txt files can be uploaded",
    fansLinkComponents23:"The file content is empty",
    fansLinkComponents24:"The total number of accounts must not exceed",
    fansLinkComponents25:"Individual",
    fansLinkComponents26:"Add up to:"
}