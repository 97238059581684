export const dialogProps = {
  show: Boolean,
  title: String,
  loading:Boolean,
  clickModal:{
    type: Boolean,
    default: true
  },
  width: {
    type: String,
    default: '34%'
  },
  show_footer:{
    type: Boolean,
    default: true
  }
}
