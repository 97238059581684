export default {
    fansLabelList1:"Fan tag",
    fansLabelList2:"Tag Name：",
    fansLabelList3:"Please enter content",
    fansLabelList4:"Search",
    fansLabelList5:"Reset",
    fansLabelList6:"Creat New Tags",
    fansLabelList7:"Batch deletion",
    fansLabelList8:"Operate",
    fansLabelList9:"Edit",
    fansLabelList10:"Delete",
    fansLabelList11:"Edit tag",
    fansLabelList12:"Creat New Tags",
    fansLabelList13:"Please select a label to delete",
    fansLabelList14:"Delete tag",
    fansLabelList15:"You sure you want to delete it？",
    fansLabelList16:"Successful operation",
    fansLabelList17:"Checkbox",
    fansLabelList18:"Tag name",
    fansLabelList19:"Usage Count",
    fansLabelList20:"Last Edited Time"
}