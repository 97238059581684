import axios from '/@/utils/request'


const api = {
    getList: '/base/comm/getWorkOrderList',
    addOrUpdate:'/base/comm/saveWorkOrder',
    getWorkOrder:'/base/open/getWorkOrder',
    resetWorkOrder:'/base/comm/resetWorkOrder',
    statusWorkOrder:'/base/comm/statusWorkOrder',
    getWorkOrderAccount:'/base/open/getWorkOrderAccount',
    deleteWorkOrder:'/base/comm/deleteWorkOrder',

    createWorkOrderLiveLink:'/base/open/createWorkOrderLiveLink',
    workOrderLiveLinkList:'/base/open/workOrderLiveLinkList',
    statusWorkOrderAccount:'/base/open/statusWorkOrderAccount',
    clearWorkOrderLiveLink:'/base/open/clearWorkOrderLiveLink',
    deleteWorkOrderLiveLink:'/base/open/deleteWorkOrderLiveLink',
    verifyPassword:'/base/open/verifyPassword',
    deleteWorkOrderAccount:'/base/open/deleteWorkOrderAccount',
    resetWorkOrderAccount:'/base/comm/batchResetWorkOrderAccount',

    // 工单粉丝明细
    getOrderFensList:'/base/comm/getWorkOrderAccountFansList',
    // 导出工单粉丝明细
    getOrderFensExcel:'/base/comm/getWorkOrderAccountFansExcel',

}

const getList = (params:any) => {
    return axios({
        url: api.getList,
        method: 'post',
        data:params
    })
}
const addOrUpdate = (params:any) => {
    return axios({
        url: api.addOrUpdate,
        method: 'post',
        data: params
    })
}
const getWorkOrder = (params:any) => {
    return axios({
        url: api.getWorkOrder,
        method: 'post',
        data: params
    })
}
const resetWorkOrder =(data:any)=>{
    return axios({
        url: api.resetWorkOrder,
        method: 'post',
        data
    })
}
const statusWorkOrder =(data:any)=>{
    return axios({
        url: api.statusWorkOrder,
        method: 'post',
        data
    })
}
const getWorkOrderAccount =(data:any)=>{
    return axios({
        url: api.getWorkOrderAccount,
        method: 'post',
        data
    })
}
const deleteWorkOrder =(data:any)=>{
    return axios({
        url: api.deleteWorkOrder,
        method: 'post',
        data
    })
}
const createWorkOrderLiveLink =(data:any)=>{
    return axios({
        url: api.createWorkOrderLiveLink,
        method: 'post',
        data
    })
}
const workOrderLiveLinkList =(data:any)=>{
    return axios({
        url: api.workOrderLiveLinkList,
        method: 'post',
        data
    })
}
const statusWorkOrderAccount =(data:any)=>{
    return axios({
        url: api.statusWorkOrderAccount,
        method: 'post',
        data
    })
}

const clearWorkOrderLiveLink =(data:any)=>{
    return axios({
        url: api.clearWorkOrderLiveLink,
        method: 'post',
        data
    })
}
const deleteWorkOrderLiveLink =(data:any)=>{
    return axios({
        url: api.deleteWorkOrderLiveLink,
        method: 'post',
        data
    })
}

const verifyPassword =(data:any)=>{
    return axios({
        url: api.verifyPassword,
        method: 'post',
        data
    })
}
const deleteWorkOrderAccount =(data:any)=>{
    return axios({
        url: api.deleteWorkOrderAccount,
        method: 'post',
        data
    })
}

const ResetWorkOrder =(data:any)=>{
    return axios({
        url: api.resetWorkOrderAccount,
        method: 'post',
        data
    })
}
 // 工单粉丝明细
const getOrderFensList =(params:any)=>{
    return axios({
        url: api.getOrderFensList,
        method: 'get',
        params
    })
}
 // 导出工单粉丝明细
const getOrderFensExcel =(params:any)=>{
    return axios({
        url: api.getOrderFensExcel,
        method: 'get',
        responseType:'blob',
        params
    })
}

export {
    getList,
    addOrUpdate,
    getWorkOrder,
    resetWorkOrder,
    statusWorkOrder,
    getWorkOrderAccount,
    deleteWorkOrder,
    createWorkOrderLiveLink,
    workOrderLiveLinkList,
    statusWorkOrderAccount,
    clearWorkOrderLiveLink,
    deleteWorkOrderLiveLink,
    verifyPassword,
    deleteWorkOrderAccount,
    ResetWorkOrder,
    getOrderFensList,
    getOrderFensExcel
}
