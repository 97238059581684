export default {
    workOrderFansDetail1:"工单管理",
    workOrderFansDetail2:"工单详情",
    workOrderFansDetail3:"工单粉丝明细",
    workOrderFansDetail4:"粉丝账号",
    workOrderFansDetail5:"粉丝昵称",
    workOrderFansDetail6:"粉丝头像",
    workOrderFansDetail7:"添加时间",
    workOrderFansDetail8:"粉丝类型",
    workOrderFansDetail9:"全部",
    workOrderFansDetail10:"新粉",
    workOrderFansDetail11:"重粉",
    workOrderFansDetail12:"底粉",
    workOrderFansDetail13:"导出当日数据",
    workOrderFansDetail14:"导出全部数据"
}