export default {
    register1:"前往官网",
    register2:"注册成功即可享受旗舰版3个端口24小时免费试用",
    register3:"请输入电子邮箱账号",
    register4:"邮箱验证码",
    register5:"获取验证码",
    register6:"请输入密码",
    register7:"确认密码",
    register8:"请输入邮箱",
    register9:"请输入正确邮箱格式",
    register10:"当前不支持该邮箱，请更换其他邮箱，推荐谷歌邮箱(gmail)",
    register11:"请输入验证码",
    register12:"请输入密码",
    register13:"发送成功",
    register14:"密码由6-16位的数字字母组成",
    register15:"两次密码不一致",
    register16:"注册",
    register17:"注册成功",
    register18:"注册账号",
    register19:"取消"
}