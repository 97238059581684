export default {
    codeFriendsDetail1:"好友明细",
    codeFriendsDetail2:"时间：",
    codeFriendsDetail3:"结束日期",
    codeFriendsDetail4:"开始日期",
    codeFriendsDetail5:"好友ID：",
    codeFriendsDetail6:"请输入",
    codeFriendsDetail7:"好友昵称：",
    codeFriendsDetail8:"粉丝类型：",
    codeFriendsDetail9:"查找",
    codeFriendsDetail10:"重置",
    codeFriendsDetail11:"头像",
    codeFriendsDetail12:"粉丝标签",
    codeFriendsDetail13:"跟进记录",
    codeFriendsDetail14:"粉丝类型",
    codeFriendsDetail15:"新粉",
    codeFriendsDetail16:"重粉",
    codeFriendsDetail17:"底粉",
    codeFriendsDetail18:"好友ID",
    codeFriendsDetail19:"好友昵称",
    codeFriendsDetail20:"好友头像",
    codeFriendsDetail21:"添加时间",
    codeFriendsDetail22:"粉丝备注",
    codeFriendsDetail23:"粉丝标签",
    codeFriendsDetail24:"跟进记录",
    codeFriendsDetail25:"粉丝类型",
    codeFriendsDetail26:"全部",
    codeFriendsDetail27:"粉丝备注：",

    codeFriendsDetail28: "粉丝昵称",
    codeFriendsDetail29: "粉丝账号",
}