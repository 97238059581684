export default {
    codeFriendsDetail1:"Friends details",
    codeFriendsDetail2:"Time：",
    codeFriendsDetail3:"End date",
    codeFriendsDetail4:"Start date",
    codeFriendsDetail5:"Friend ID：",
    codeFriendsDetail6:"Please enter",
    codeFriendsDetail7:"Friend nickname：",
    codeFriendsDetail8:"Fan type：",
    codeFriendsDetail9:"Search",
    codeFriendsDetail10:"Reset",
    codeFriendsDetail11:"Profile-image",
    codeFriendsDetail12:"Fan tag",
    codeFriendsDetail13:"Follow up records",
    codeFriendsDetail14:"Fan type",
    codeFriendsDetail15:"New fan",
    codeFriendsDetail16:"New Fans",
    codeFriendsDetail17:"Existing Fans",
    codeFriendsDetail18:"Friend ID",
    codeFriendsDetail19:"Friend nickname",
    codeFriendsDetail20:"Friend avatar",
    codeFriendsDetail21:"Add time",
    codeFriendsDetail22:"Notes from fans",
    codeFriendsDetail23:"Fan tag",
    codeFriendsDetail24:"Follow up records",
    codeFriendsDetail25:"Fan type",
    codeFriendsDetail26:"All",
    codeFriendsDetail27:"Fan remark",

    codeFriendsDetail28: "Fan Nickname",
    codeFriendsDetail29: "Fan Account",
}