

import mqtt from "mqtt/dist/mqtt.js";

import { uuid } from 'vue-uuid';

import type { MqttClient, OnMessageCallback } from 'mqtt';



class MQTT {
    url: string; // mqtt地址
    client!: MqttClient;
   
    constructor() {
      this.url= import.meta.env.VITE_MTTQ_URL as string | undefined;
    }
   
    //初始化mqtt
    init() {
        const options = {
            clientId: uuid.v1(),
            username: "clientadmin",
            password: "sdtclientadmin",
            keepalive: 30,
            path: "/mqtt"
        };
      this.client = mqtt.connect(this.url, options);
   
      this.client.on('error', (error: any) => {
        console.log(this.url + "异常中断");
      });
   
      this.client.on('reconnect', (error: Error) => {
        console.log(this.url + "重新连接");
      });
    }
   
    //取消订阅
    unsubscribes(topic: string) {
      this.client.unsubscribe(topic, (error: Error) => {
        if (!error) {
          console.log(topic + '取消订阅成功');
        } else {
          console.log(topic + '取消订阅失败');
        }
      });
    }
   
    //连接
    link(topic: string) {
      this.client.on('connect', () => {
        this.client.subscribe(topic, (error: any) => {
          if (!error) {
            console.log(topic + '订阅成功');
          } else {
            console.log(topic + '订阅失败');
          }
        });
      });
    }
   
    //收到的消息
    get(callback: OnMessageCallback) {
      this.client.on('message', callback);
    }
   
    //结束链接
    over() {
      this.client.end();
    }
  }
  export default MQTT;


