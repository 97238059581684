import MQTT from './mqtt'
import type { OnMessageCallback } from 'mqtt'
import {
    ref,
    onUnmounted
} from 'vue'
 
export default function useMqtt() {
    const PublicMqtt = ref<MQTT | null>(null)
    const linkList = Array<string>() // 长连接的列表
 
    const startMqtt = (callback: OnMessageCallback) => {
        PublicMqtt.value = new MQTT()// 创建连接
        PublicMqtt.value.init()// 初始化mqtt
        linkList.forEach((topic) => { PublicMqtt.value?.unsubscribes(topic); PublicMqtt.value?.link(topic) })// 订阅主题
        getMessage(callback)
    }
 
    const addLink = (topic: string) => {
        if (!linkList.includes(topic)) { // 简单地去重
            linkList.push(topic)
            PublicMqtt.value?.link(topic)
        }
    }
 
    const Uint8ArrayToString = (fileData: Uint8Array) => {
        let dataString = ''
        for (let i = 0; i < fileData.length; i++) {
            dataString += String.fromCharCode(fileData[i])
        }
        return dataString
    }
 
    const getMessage = (callback: Function) => {
        PublicMqtt.value?.get((t, m: any) => { 
            callback(t, Uint8ArrayToString(m)) 
        })
    }
 
    onUnmounted(() => {// 页面销毁结束订阅
        linkList.forEach((topic) => { PublicMqtt.value?.unsubscribes(topic) })
        PublicMqtt.value?.over()
    })
 
    return {
        startMqtt,
        addLink
    }
}