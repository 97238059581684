import axios from '/@/utils/request'

const api = {
  getList:"/base/comm/fansLiveLinkList",
  createLink:"/base/comm/createFansLiveLink",
  delLink:'/base/comm/deleteFansLiveLink',
  getFansLiveAccount:'/base/comm/getFansLiveAccount',
  editLink:'/base/comm/editFansLiveLink'
}

export const getList = (params:any) => {
  return axios({
    url: api.getList,
    method: 'post',
    data:params
  })
}

export const createLink = (params:any) => {
  return axios({
    url: api.createLink,
    method: 'post',
    data:params
  })
}
export const delLink = (params:any) => {
  return axios({
    url: api.delLink,
    method: 'post',
    data:params
  })
}
export const getFansLiveAccount = (params:any) => {
  return axios({
    url: api.getFansLiveAccount,
    method: 'get',
    params
  })
}
export const editLink = (params:any) => {
  return axios({
    url: api.editLink,
    method: 'post',
    data:params
  })
}