import axios from "/@/utils/request";

const api = {
  getLabelList: "/base/comm/getFansLabelList",
  editLabel: "/base/comm/saveFansLabel",
  delLabel: "/base/comm/delFansLabel",
  getFansTrack: "/base/comm/getSocialAccountFansTrack",
  getWorkOrderAccountLog: "/base/comm/getWorkOrderAccountLog",
};

export const getLabelList = (params: any) => {
  return axios({
    url: api.getLabelList,
    method: "get",
    params,
  });
};

export const editLabel = (params: any) => {
  return axios({
    url: api.editLabel,
    method: "post",
    data: params,
  });
};

export const delLabel = (params: any) => {
  return axios({
    url: api.delLabel,
    method: "delete",
    data: params,
  });
};

export const getFansTrack = (params: any) => {
  return axios({
    url: api.getFansTrack,
    method: "get",
    params,
  });
};

export const getWorkOrderAccountLog = (params: {
  accountId: number;
  workOrderId: number;
}) => {
  return axios({
    url: api.getWorkOrderAccountLog,
    method: "post",
    data: params,
  });
};
