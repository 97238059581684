export default {
    layout1:"初始密码：",
    layout2:"请输入初始密码",
    layout3:"修改密码：",
    layout4:"请输入密码",
    layout5:"确认密码：",
    layout6:"请输入确认密码",
    layout7:"确 定",
    layout8:"取 消",
    layout9:"点击联系客服",
    layout10:"修改密码",
    layout11:"粉丝标签",
    layout12:"以字母开头，长度在6~16之间，只能包含字母、数字和下划线",
    layout13:"两次密码不一致",
    layout14:"修改成功",
    layout15:"续费提醒",
    layout16:"验证",
    layout17:"通过",
    layout18:"验证成功",
    layout19:"验证失败",
    layout20:"验证操作失败请稍后再试",
}
