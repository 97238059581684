export default {
    workOrderDetail1:"Fan Count Ticket",
    workOrderDetail2:"Ticket Details",
    workOrderDetail3:"Account",
    workOrderDetail4:"Belonging platform",
    workOrderDetail5:"Allocation Status",
    workOrderDetail6:"Online Status",
    workOrderDetail7:"Account avatar",
    workOrderDetail8:"Nickname",
    workOrderDetail9:"Daily New Fans/Daily Fan Limit",
    workOrderDetail10:"Completed/Total Goal",
    workOrderDetail11:"Today Fans Duplicated",
    workOrderDetail12:"Total Number of Fans Duplicated",
    workOrderDetail13:"Account Link",
    workOrderDetail14:"Account Link",
    workOrderDetail15:"Fans Detail",
    workOrderDetail16:"Name",
    workOrderDetail17:"Copy live chain",
    workOrderDetail18:"Number of account links",
    workOrderDetail19:"User binding",
    workOrderDetail20:"Clicks",
    workOrderDetail21:"Number of IPs"
}