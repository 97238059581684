export default {
    codeDetail1:"邀请码查看",
    codeDetail2:"社交平台：",
    codeDetail3:"请选择",
    codeDetail4:"平台昵称：",
    codeDetail5:"请输入",
    codeDetail6:"用户名：",
    codeDetail7:"请输入",
    codeDetail8:"查找",
    codeDetail9:"重置",
    codeDetail10:"活链管理",
    codeDetail11:"用户名",
    codeDetail12:"头像",
    codeDetail13:"状态",
    codeDetail14:"在线",
    codeDetail15:"离线",
    codeDetail16:"备注",
    codeDetail17:"保存",
    codeDetail18:"操作",
    codeDetail19:"查看好友明细",
    codeDetail20:"生成活链",
    codeDetail21:"活链管理",
    codeDetail22:"id",
    codeDetail23:"平台",
    codeDetail24:"用户名",
    codeDetail25:"昵称",
    codeDetail26:"首登时间",
    codeDetail27:"最后一次登录时间",
    codeDetail28:"粉丝量(已去重)",
    codeDetail29:"重粉数量",
    codeDetail30:"备注",
    codeDetail31:"名称",
    codeDetail32:"复制活链",
    codeDetail33:"账号链接数量",
    codeDetail34:"用户绑定",
    codeDetail35:"点击数",
    codeDetail36:"IP数",
    codeDetail37:"暂无备注",
    codeDetail39:"社交平台",
    codeDetail40:"入粉时间",
    codeDetail41:"开始时间",
    codeDetail42:"结束时间",
    codeDetail43:"粉丝昵称",
    codeDetail44:"序号",
    codeDetail45:"社交账号",
    codeDetail46:"粉丝信息",
    codeDetail47:"无",
    codeDetail48:"获取跟进记录失败",
    codeDetail49:"获取粉丝标签失败",
    codeDetail50:"粉丝数据",
    codeDetail51:"总登陆账号数量",
    codeDetail52:"当前在线账号",
    codeDetail53:"离线账号",
    codeDetail54:"分配端口",
    codeDetail55:"粉丝情况",

    codeDetail56:"当日进粉总数（已去重）",
    codeDetail57:"当日重粉量",
    codeDetail58:"上次手动重置粉丝数据时间",
    codeDetail59:"进粉总数（已去重）",
    codeDetail60:"总重粉量",
    codeDetail61:"每日入粉置零时间",
    codeDetail62:"活链链接",

    codeDetail63: "账号",
    codeDetail64: "账号昵称",
    codeDetail65: "平台类型",
    codeDetail66: "开启活链分配",
    codeDetail67: "关闭活链分配",
    codeDetail68: "批量账号重置",
    codeDetail69: "批量账号移除",

    codeDetail70: "活链分配状态",

    codeDetail71: "关闭",
    codeDetail72: "开启",
    codeDetail73: "请设置账号ID",
    codeDetail74: "不支持的平台",

    codeDetail75: "在线状态",
    codeDetail76: "昵称",
    codeDetail77: "当日入粉",
    codeDetail78: "入粉总数",
    codeDetail79: "当日重粉",
    codeDetail80: "重粉总数",
    codeDetail81: "账号链接地址",
    codeDetail82: "点击复制",
    codeDetail83: "日志",
    codeDetail84: "数据重置",
    codeDetail85: "账号删除",
    codeDetail86: "查看粉丝明细",
    codeDetail87: "账号下线",

    codeDetail88: "提示",
    codeDetail89: "该账号正在使用，确认要下线吗？",
    codeDetail90: "确认",
    codeDetail91: "取消",
    codeDetail92: "确认执行此操作后，所有新粉丝数据将被清除并转变为底粉，新粉丝总数将发生变化。并且不可恢复，您是否确认继续？",
    codeDetail93: "确认执行批量移除操作吗？请注意，此操作将不可恢复，且在线的账号将会被系统自动跳过。",
    codeDetail94: "确认移除吗？不可恢复，并且账号数据将会全部清除。",

    codeDetail95: "选择不限制，启动端口会减少账号端口整余额",
    codeDetail96: "计数器功能",
    codeDetail97: "在无需保留粉丝数据情况下，强烈推荐不开启状态提升效率。",
    codeDetail98: "分享密码",
    codeDetail99: "新粉置零时间",
    codeDetail100: "活链用户绑定",
    codeDetail101: "开启IP绑定",
    codeDetail102: "切换后，粉丝计数将不被记录，在修改确认后客户端会强制重新登录。此外，切换模式后将无法使用工单功能，请确认是否进行此切换？",
    codeDetail103: "切换后，在修改确认后客户端会强制重新登录，请确认是否进行此切换？",
    codeDetail104: "开启IP绑定后，在符合条件的号码中，以顺序循环的方法进行切换。同一个设备每次访问连接同一个号码，防止同一个客户添加多个号码",
    codeDetail105: "关闭后，同一个设备每次访问都将重新分配一个新的号码，有可能一个用户添加多个号码",

    codeDetail106: "4~8位",
    codeDetail107: "此修改后确认，需客户端重新登陆操作后方才能生效。",
    codeDetail108: "请输入工单分享密码",
    codeDetail109: "长度在 4 到 8 个字符",
    codeDetail110: "请选择新粉置零时间",

    codeDetail111: "请选择需要操作的数据",
}