export default{
    firstLogin1: 'Welcome to %channelName% Translator SCRM !',
    firstLogin2: 'We will help you connect with global users, unlock the limitless potential of the global market, and achieve significant business growth.',
    firstLogin3: 'Use Guide',
    firstLogin4: '1、Click on Invitation Code：',
    firstLogin5: '2、Create an Invitation Code：',
    firstLogin6: 'Click on the "Create Invitation Code" button located in the upper right corner of the page.',
    firstLogin7: '3、Complete the Creation of the Invitation Code：',
    firstLogin8: 'Fill in the required parameters for the invitation code and complete the creation process.',
    firstLogin9: '4、Use client：',
    firstLogin10: 'Download the %channelName% client, enter the invitation code, and start experiencing the features of the %channelName% product.',
    firstLogin11: 'Limited-Time Promotion: First Come, First Served',
    firstLogin12: 'Navigate to the "Invitation Code " module .'
}
