export default {
  register1: 'Go to official website',
  register2:
    'After successful registration, you can enjoy a 24-hour free trial of 3 ports of the flagship version.',
  register3: 'Please enter email account',
  register4: 'E-mail verification code',
  register5: 'Send code',
  register6: 'Please enter password',
  register7: 'Confirm Password',
  register8: 'Please input your email',
  register9: 'Please enter the correct email format',
  register10:
    'This email is currently not supported, please change to another email. Google email (gmail) is recommended.',
  register11: 'Please enter verification code',
  register12: 'Please enter password',
  register13: 'Sent successfully',
  register14: 'The password consists of 6-16 alphanumeric characters',
  register15: 'Two passwords are inconsistent',
  register16: 'Register',
  register17: 'Registration success',
  register18: 'REGISTER AN ACCOUNT',
  register19: 'Cancel',
};