export default {
    reset1:"Go to official website",
    reset2:"Please enter email account",
    reset3:"E-mail verification code",
    reset4:"Send code",
    reset5:"Please enter password",
    reset6:"Confirm Password",
    reset7:"Modify",
    reset8:"Cancel",
    reset9:"Kind tips",
    reset10:"Please input your email",
    reset11:"Please enter the correct email format",
    reset12:"Please enter verification code",
    reset13:"Please enter password",
    reset14:"Sent successfully",
    reset15:"The password consists of 6-16 alphanumeric characters",
    reset16:"Two passwords are inconsistent",
    reset17:"Modify",
    reset18:"Successfully modified",
    reset19:"FORGET THE PASSWORD",
}