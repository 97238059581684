export default {
    layout1:"Initial Password：",
    layout2:"Please enter initial password",
    layout3:"Change Password：",
    layout4:"Please enter password",
    layout5:"Confirm Password：",
    layout6:"Please enter the confirmation password",
    layout7:"Determine",
    layout8:"Cancel",
    layout9:"Click to contact customer service",
    layout10:"Change Password",
    layout11:"Fan Tag",
    layout12:"Starts with a letter, has a length between 6 and 16, and can only contain letters, numbers and underscores.",
    layout13:"Two passwords are inconsistent",
    layout14:"Successfully modified",
    layout15:"Renewal reminder",
    layout16:"Verify",
    layout17:"Pass",
    layout18:"Verification successful",
    layout19:"Verification failed",
    layout20:"Verification operation failed please try again later",
}
